<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{ $t("message.telegramBotSetting") }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                       <el-form-item prop="name" :label="$t('message.name')">
                            <el-input
                                :placeholder="$t('message.name')"
                                v-model="form.name"
                                size="medium"
                            ></el-input>
                       </el-form-item>     
                    </el-col>

                    <el-col :span="24">
                       <el-form-item prop="filial_id" :label="$t('message.filial')">
                          <select-filial
                            :size="'medium'"
                            :placeholder="$t('message.filial')"
                            :id="form.filial_id"
                            v-model="form.filial_id"
                          >
                          </select-filial>
                       </el-form-item>     
                    </el-col>

                    <el-col :span="24">
                       <el-form-item prop="token" :label="$t('message.token')">
                            <el-input
                                :placeholder="$t('message.token')"
                                v-model="form.token"
                                size="medium"
                            ></el-input>
                       </el-form-item>     
                    </el-col>

                    <el-col :span="24">
                       <el-form-item prop="comment" :label="$t('message.comment')">
                            <el-input
                                autosize
                                type="textarea"
                                :autosize="{ minRows: 2 }"
                                :placeholder="$t('message.comment')"
                                v-model="form.comment"
                                size="medium"
                            ></el-input>
                       </el-form-item>     
                    </el-col>

                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectFilial from "@/components/inventory/select-filial";

export default {
  
  mixins: [form, drawer],
  components: {
    selectFilial
  },
  data() {
    return {

    };
  },
  
  created() {},
  computed: {
      ...mapGetters({
          rules: "telegramBotSetting/rules",
          model: "telegramBotSetting/model",
          columns: "telegramBotSetting/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "telegramBotSetting/store",
      }),
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>
